<template>
  <div class="max-container">
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="" dark>
        <div class="py-3">
          <div class="px-3">Please consult it to maintain your account profiles.</div>
          <v-list disabled dense>
            <v-subheader style="height:20px;">Profiles</v-subheader>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon style="margin-right:16px;">
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div class="profile-list-desc px-3 py-1">
            <h4>NOTE:</h4>
            <div class="desc-text step2-desc">
              <ul>
                <li>After logging into a channel, pick the profile under your name</li>
                <li>If your profile doesn’t exist yet, you can create one with your profile name</li>
                <li>Please do not delete other user's profile</li>
                <li>Any unrecognized profile will be automatically purged without notice.</li>
              </ul>
            </div>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'ProfileList',
  data: () => ({
    sheet: false,
    selectedItem: 1,
    items: [
      { text: 'Real-Time', icon: 'mdi-account' },
      { text: 'Audience', icon: 'mdi-account' },
      { text: 'Conversions', icon: 'mdi-account' }
    ]
  }),
  methods: {
    show() {
      this.sheet = true
    },
    hide() {
      this.sheet = false
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-list-desc{
    color: rgb(176 176 176)
}
</style>
