<template>
  <v-container class="max-container">
    <v-card class="pa-3 card-box" flat dark color="black">
      <v-row dense>
        <v-col>
          <div v-show="channelList.length===0" class="no-data-text">
            You have not subscribe any channel.
          </div>
        </v-col>
        <v-col
          v-for="(item, i) in channelList"
          :key="i"
          cols="12"
        >
          <v-card
            :color="'#8C9EFF'"
            dark
          >
            <div class="platform-card">
              <div>
                <v-card-title
                  class="text-h6"
                  @click="copyAccount(item.account, 'Account')"
                >
                  <span class="account-text">Account: {{ item.account }}</span><v-icon v-if="item.account!=='*************'" x-small>mdi-content-copy</v-icon>
                </v-card-title>

                <v-card-subtitle class="text-h6">
                  <span class="account-text" @click="copyAccount(item.password, 'Password')">Password: {{ item.password }}</span>
                  <v-icon v-if="item.account!=='*************'" x-small @click="copyAccount(item.password, 'Password')">mdi-content-copy</v-icon><br>
                  <span class="account-text">Profile: {{ profile.profile_name }}</span><br>
                  <span v-if="item.paygo" class="account-expire">Expire: {{ item.expire }}</span>
                </v-card-subtitle>
                <!-- <v-card-subtitle class="text-h5" v-text="'Profile: '+ item.profile_name" /> -->
                <v-card-actions>
                  <!-- <v-btn
                    class="ml-2 text-none"
                    rounded
                    small
                    @click="resetPasswordDialog(item)"
                  >
                    Reset Password
                  </v-btn> -->
                  <v-btn
                    class="ml-2 text-none"
                    rounded
                    small
                    @click="getAccount(item)"
                  >
                    Get Password
                  </v-btn>
                  <!-- <v-btn
                    v-if="item.platform_name !='espn'"
                    class="ml-2 text-none"
                    rounded
                    small
                    @click="viewAccountProfiles(item)"
                  >
                    View Profiles
                  </v-btn> -->
                  <!-- <v-btn
                    v-if="verification_list.includes(item.platform_name)"
                    class="ml-2 text-none"
                    rounded
                    small
                    @click="getVerificationCode(item)"
                  >
                    Get Verification Code
                  </v-btn>
                  <v-btn
                    v-if="item.platform_name=='netflix'"
                    class="ml-2 text-none"
                    rounded
                    small
                    @click="openNetflixTipsDialog('house_hold')"
                  >
                    Update Household
                  </v-btn>
                  <v-btn
                    v-if="item.platform_name=='netflix'"
                    class="ml-2 text-none"
                    rounded
                    small
                    @click="openNetflixTipsDialog('access_code')"
                  >
                    Get Temporary Code
                  </v-btn> -->
                </v-card-actions>
              </div>
              <div class="platform-avator">
                <v-avatar
                  class="ma-4 brand-letter"
                  tile
                >
                  <v-img width="60" :src="item.logo">
                    <!-- {{ item.platform_name }} -->
                  </v-img></v-avatar>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- <div class="button-box">
      <v-btn block depressed large class="tile text-capitalize" @click="toSub">Get More Account</v-btn>
    </div> -->
    <c-snack-bar ref="csnackbar" />
    <over-lay-loading ref="overlayloading" />
    <over-dialog ref="overdialog" :ok-handle="resetPassword" />
    <over-dialog ref="getCodeOverdialog" :ok-handle="closeCodeDialog" />
    <over-dialog ref="netflixTipsDialog" :ok-handle="fetchNetflixEmail" />
    <get-account-dialog ref="getAccountDialog" :platform="selectedPlatform" :account="account" :password="password" />
    <ProfileList ref="profileListRef" />
  </v-container>
</template>

<script>

import { getChannel, resetpwd, getAccount, getManualSubList, getProfileInfo,
  getVerificationCode, fetchNetflixEmail, getAcctProfiles } from '@/api/user'
import OverLayLoading from './components/overLayLoading'
import OverDialog from './components/overDialog'
import CSnackBar from './components/snackbar'
import getAccountDialog from '@/views/auth/components/getAccount'
import ProfileList from '@/views/auth/components/profileList'

export default {
  name: 'Account',
  components: {
    OverDialog,
    OverLayLoading,
    CSnackBar,
    getAccountDialog,
    ProfileList
  },
  data: () => ({
    profile: {
      profile_name: '*******'
    },
    channelList: [],
    active_item: null,
    verification_list: ['hulu', 'prime video', 'disney plus'],
    netflixType: '',
    selectedPlatform: '',
    account: '',
    password: '',
    channel: ''
  }),
  computed: {
    userSubscribeChannels() {
      const sub = this.$store.state.user.sub_data
      if (sub) {
        return sub.map((item) => item.platform_name)
      }
      return []
    },
    payGoPlatforms() {
      const userInfo = this.$store.state.user.userInfo
      return userInfo.manual_sub_platforms.split(';')
    }
  },
  created() {
    const channel = this.$route.query.channel
    if (channel) {
      this.channel = channel
    }
    this.$nextTick(() => {
      this.get_channel()
      this.getProfileInfo()
    })
  },
  methods: {
    getProfileInfo() {
      getProfileInfo().then(res => {
        if (res.code === 0) {
          this.profile = res.data
        }
      })
    },
    async get_channel() {
      this.$refs.overlayloading.controlShow(true)
      const manualListRes = await getManualSubList()
      getChannel()
        .then(
          (res) => {
            this.channelList = res.data
              .filter((item) => {
                if (item.status === 2) {
                  // 暂停使用的channel
                  return this.userSubscribeChannels.concat(this.payGoPlatforms).includes(
                    item.platform_name
                  )
                }

                return item.status === 0
              })
              .map((item) => {
                item.check =
                  this.userSubscribeChannels.concat(this.payGoPlatforms).findIndex(
                    (s) => item.platform_name === s
                  ) > -1
                item.logo = this.getAppLogo(item.platform_name)
                item.account = '*************'
                item.password = '*************'
                item.profile_name = '*******'
                item.paygo = this.payGoPlatforms.includes(item.platform_name)
                item.expire = '*******'

                const manual_item = manualListRes.data.find(t => t.platform === item.platform_name)
                if (manual_item) {
                  item.expire = this.$moment.unix(manual_item.end_time).format('YYYY-MM-DD HH:mm:ss')
                }
                return item
              }).filter(item => {
                return item.check
              })

            if (this.channel) {
              const showPasswordChannel = this.channelList.find(item => item.platform_name === this.channel)
              if (showPasswordChannel) { this.getAccount(showPasswordChannel) }
            }
          },
          () => {}
        )
        .finally(() => {
          this.$refs.overlayloading.controlShow(false)
        })
    },
    getAppLogo(platform_name) {
      const logo = {
        'netflix': 'https://www.onechannel.one/wp-content/uploads/2022/10/netflix.png',
        'disney plus': 'https://www.onechannel.one/wp-content/uploads/2022/10/disney-plus.png',
        'hbo': 'https://www.onechannel.one/wp-content/uploads/2022/10/hbo.png',
        'hulu': 'https://www.onechannel.one/wp-content/uploads/2022/10/hulu.png',
        'espn': 'https://www.onechannel.one/wp-content/uploads/2022/10/espn.png',
        'spotify': 'https://www.onechannel.one/wp-content/uploads/2022/10/spotify.png',
        'paramount plus': 'https://www.onechannel.one/wp-content/uploads/2022/11/paramount-plus.png',
        'prime video': 'https://www.onechannel.one/wp-content/uploads/2023/03/prime-video.png'
      }
      return logo[platform_name]
    },
    resetPasswordDialog(item) {
      this.active_item = item
      this.$refs.overdialog.controlDialog(true, 'The automatic password reset request can only be requested once within 48 hours. Are you sure to send the reset request?', 'Reset Password')
    },
    resetPassword() {
      resetpwd({ 'platform': this.active_item.platform_name }).then(res => {
        if (res.code === 0) {
          const data = res.data
          // eslint-disable-next-line
          if (data.hasOwnProperty('msg')) {
            this.showNackBar('info', data.msg)
          }
        }
      }, () => {
        this.showNackBar('error', 'System error, please try again later!')
      }).finally(() => {
        this.$refs.overdialog.controlLoading(false)
        this.$refs.overdialog.controlShowDialog(false)
      })
    },
    getAccount(item) {
      this.$refs.overlayloading.controlShow(true)
      getAccount({ 'identifier': item.platform_name }).then(res => {
        if (res.code !== 0) {
          this.showNackBar('error', res.message)
          return
        }
        this.showNackBar('success', 'Success')
        item.account = res.data.account
        item.password = res.data.password
        item.profile_name = res.data.pin_name
        this.getProfileInfo()

        // 弹框显示账号以及密码
        this.account = res.data.account
        this.password = res.data.password
        this.selectedPlatform = item.platform_name.charAt(0).toUpperCase() + item.platform_name.slice(1)
        if (['espn', 'hbo', 'paramount plus', 'disney plus'].includes(item.platform_name)) {
          const channel_map = { 'espn': 'ESPN+', 'hbo': 'HBOMAX', 'paramount plus': 'Paramount+', 'disney plus': 'Disney+' }
          this.selectedPlatform = channel_map[item.platform_name]
        }
        this.$refs.getAccountDialog.dialog = true
      }, () => {
        this.showNackBar('error', 'System error, please try again later!')
      }).finally(() => {
        this.$refs.overlayloading.controlShow(false)
      })
    },
    getVerificationCode(item) {
      this.$refs.overlayloading.controlShow(true)
      getVerificationCode({ 'platform': item.platform_name }).then(res => {
        if (res.code !== 0) {
          this.showNackBar('error', res.message)
          return
        }
        this.$refs.getCodeOverdialog.controlDialog(true, res.data.msg, 'Note', false, false)
      }, () => {
        this.showNackBar('error', 'System error, please try again later!')
      }).finally(() => {
        this.$refs.overlayloading.controlShow(false)
      })
    },
    fetchNetflixEmail() {
      this.$refs.overlayloading.controlShow(true)
      fetchNetflixEmail({ 'type': this.netflixType }).then(res => {
        if (res.code !== 0) {
          this.showNackBar('error', res.message)
          return
        }
        this.$refs.getCodeOverdialog.controlDialog(true, res.data.msg, 'Note', false, false)
      }, error => {
        console.log(error)
      }).finally(() => {
        this.$refs.netflixTipsDialog.controlLoading(false)
        this.$refs.netflixTipsDialog.controlShowDialog(false)
        this.$refs.overlayloading.controlShow(false)
      })
    },
    openNetflixTipsDialog(type) {
      this.netflixType = type
      if (type === 'house_hold') {
        this.$refs.netflixTipsDialog.controlDialog(true, 'Have you sent a request to update the Netflix Household on your TV?', 'Netflix Household', false, true, 'Yes', 'Close')
      } else {
        this.$refs.netflixTipsDialog.controlDialog(true, 'Have you sent a request to get the Netflix temporary access code on your TV?', 'Netflix temporary access code', false, true, 'Yes', 'Close')
      }
    },
    viewAccountProfiles(item) {
      this.$refs.profileListRef.show()
      getAcctProfiles({ 'platform': item.platform_name })
    },
    closeCodeDialog() {
      this.$refs.getCodeOverdialog.controlLoading(false)
      this.$refs.getCodeOverdialog.controlShowDialog(false)
    },
    toSub() {

    },
    showNackBar(color, message, timeout = 5000) {
      this.$refs.csnackbar.show(message, color, timeout)
    },
    copyAccount(value, type) {
      if (value === '*************') {
        return
      }

      if (!navigator.clipboard) {
        console.log('not supported')
        return
      }

      if (window.isAndroidWebView() && window.AndroidCallback.copyToClipboard) {
        window.AndroidCallback.copyToClipboard(value)
        this.showNackBar('info', `${type} Copied!`)
        return
      }

      navigator.clipboard.writeText(value).then(() => {
        this.showNackBar('info', `${type} Copied!`)
        console.log('Async: Copying to clipboard was successful!')
      }, function(err) {
        console.error('Async: Could not copy text: ', err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__title + .v-card__subtitle {
   margin-top: 0px;
}

div.v-avatar{
    height: unset!important;
    width: unset!important;
    min-width: 40px!important;
}

.button-box{
//   margin-bottom: 10px;
  .v-btn{
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
}

.v-footer{
    border: unset!important;
    background-color: transparent!important;
    text-align: center;
}

.card-box{
    // height: 0vh;
    overflow-y: auto;
    .platform-avator{
      position: absolute;
      top: 10px;
      right: 10px;
    }
}

.platform-card{
  position: relative;
}

.v-card .v-card__actions {
    padding-top: 0px;
}

.brand-letter{
  letter-spacing: 0.08em;
}

.account-text{
    font-size: 12px;
}

.account-expire{
  color:red;
  font-size: 14px;
}

.v-card .v-card__actions {
    flex-wrap: wrap;
    row-gap: 10px;
}
</style>
